import axios from 'axios';
import { productsFail, productsSuccess, productsRequest,productDetailFail, productDetailSuccess, productDetailRequest} from '../slices/productsSlice';
export const getProducts = () => async (dispatch) => {
    try {  
        dispatch(productsRequest()) 
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  =  await axios.get(`${process.env.REACT_APP_API_URL}website/product_list`,config);
        dispatch(productsSuccess(data))
    } catch (error) {
        dispatch(productsFail(error.response.data.message))
    }
}

export const getProductDetail = (name) => async (dispatch) => {
    try {  
        dispatch(productDetailRequest()) 
        const config = {
            headers: {
                'site_user_service': 'website-app-user',
                'site_auth_key': 'website_jewellers'
            }
        };
        const { data }  =  await axios.get(`${process.env.REACT_APP_API_URL}website/product_detail/${name}`,config);
        dispatch(productDetailSuccess(data))
    } catch (error) {
        dispatch(productDetailFail(error.response.data.message))
    }
}
